<template>
  <b-modal id="addNewRole" title="Add New Role" @hidden="resetInfoModal" no-close-on-backdrop scrollable ok-title="Save" @ok="submitForm" size="xl">
    <b-container class="mt-1">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Name:">
                <validation-provider #default="{ errors }" name="Role Name" rules="required">
                  <b-form-input v-model="role.name" list="input-list" placeholder="Enter Role Name" :state="validation" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" style="padding-left: 0px!important">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Description:">
                <validation-provider #default="{ errors }" name="Role Description:" rules="required">
                  <b-form-input v-model="role.description" list="input-list" placeholder="Enter Role Description" :state="validationdes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" style="margin-bottom: 1rem;">
              <b-col cols="12">
                <b-tabs vertical nav-wrapper-class="nav-vertical" fill class="tab-class">
                  <b-tab title="Event Permissions">
                    <b-table hover responsive :items="p_event" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Invoice Permissions">
                    <b-table hover responsive :items="p_invoice" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Employee Permissions">
                    <b-table hover responsive :items="p_employee" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Client Permissions">
                    <b-table hover responsive :items="p_client" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Setting Permissions">
                    <b-table hover responsive :items="p_setting" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Team Permissions">
                    <b-table hover responsive :items="p_team" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="User Permissions">
                    <b-table hover responsive :items="p_user" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>

                  <b-tab title="Vehicle Permissions">
                    <b-table hover responsive :items="p_vehicle" :fields="fields">
                      <template #cell(description)="data">
                        <span>{{ data.item.description }}</span>
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="selected"> </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { BTable } from 'bootstrap-vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BTabs,
    BTab,
    BTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  props: ['getRoles', 'loading'],
  data() {
    return {
      fields: [
        { key: 'Description', sortable: false, class: 'text-left w-75', thClass: 'bg-primary text-white' },
        { key: 'Yes/No', sortable: false, class: 'text-center w-25', thClass: 'bg-primary text-white' },
      ],

      required,
      selected: [],
      permissions: [],
      p_client: [],
      p_employee: [],
      p_event: [],
      p_setting: [],
      p_team: [],
      p_user: [],
      p_vehicle: [],
      p_invoice: [],

      role: {
        name: '',
        description: '',
        permissions: '',
      },
      submitted: false,
    };
  },
  methods: {
    submitForm() {
      const self = this;
      this.$emit('loading', true);
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitted = true;
          this.$bvModal.hide('addNewRole');
          this.role.permissions = this.selected;
          store.dispatch('settings/addRole', self.role).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Role Add Successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getRoles();
              this.resetInfoModal();
            }
          }); // dispatch store action
        }
      });
    },
    getPermissions() {
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.permissions = res.data.data;
          res.data.data.forEach((p) => {
            p.group === 'client' ? this.p_client.push(p) : '';
            p.group === 'employee' ? this.p_employee.push(p) : '';
            p.group === 'event' ? this.p_event.push(p) : '';
            p.group === 'setting' ? this.p_setting.push(p) : '';
            p.group === 'team' ? this.p_team.push(p) : '';
            p.group === 'user' ? this.p_user.push(p) : '';
            p.group === 'vehicle' ? this.p_vehicle.push(p) : '';
            p.group === 'invoice' ? this.p_invoice.push(p) : '';

          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetInfoModal() {
      this.submitted = false;
      this.role.name = '';
      this.role.description = '';
      this.role.permissions = '';
      this.selected = [];
    },
    handleSelected(tr) {
      // this.$vs.notify({
      //   title: `Selected ${tr.username}`,
      //   text: `Email: ${tr.email}`
      // })
    },
  },
  computed: {
    validation() {
      return this.role.name.length > 1;
    },
    validationdes() {
      return this.role.description.length > 1;
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
